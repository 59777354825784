import { SUBSCRIPTION_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useFetchTotalObjectCount, FETCH_TOTAL_COUNT_OF_OBJECTS_BY_OBJECT_TYPE } from '../../../crmSearch/hooks/useFetchTotalObjectCount';
import { triggerWootricSurvey, PAYMENTS_SURVEY_ID, COMMERCE_SUBSCRIPTIONS_SURVEY_ID } from '../../../utils/triggerWootricSurvey';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
export const useOnPreviewSidebarClose = () => {
  var _data$FETCH_TOTAL_COU;
  const objectTypeId = useSelectedObjectTypeId();
  const {
    data
  } = useFetchTotalObjectCount({
    objectTypeId,
    limit: 15000000,
    skip: objectTypeId !== SUBSCRIPTION_TYPE_ID && objectTypeId !== COMMERCE_PAYMENT_TYPE_ID
  });
  const totalRecords = (data === null || data === void 0 || (_data$FETCH_TOTAL_COU = data[FETCH_TOTAL_COUNT_OF_OBJECTS_BY_OBJECT_TYPE]) === null || _data$FETCH_TOTAL_COU === void 0 ? void 0 : _data$FETCH_TOTAL_COU.total) || 0;
  useOnCrmMessageTopic('CLOSE_PREVIEW_OBJECT', () => {
    if (objectTypeId === COMMERCE_PAYMENT_TYPE_ID && totalRecords >= 3) {
      triggerWootricSurvey(PAYMENTS_SURVEY_ID);
    }
    if (objectTypeId === SUBSCRIPTION_TYPE_ID && totalRecords >= 2) {
      triggerWootricSurvey(COMMERCE_SUBSCRIPTIONS_SURVEY_ID);
    }
  });
};