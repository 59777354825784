import { FAILED, PENDING, UNINITIALIZED } from '../../constants/RequestStatus';
import getIn from 'transmute/getIn';
import { useEffect, useRef } from 'react';
import { usePrefetch as usePrefetchTableDeps } from 'crm-object-table/prefetch';
import { useFetchAllProperties } from '../../properties/hooks/useFetchAllProperties';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useCurrentPageType } from '../../../views/hooks/useCurrentPageType';
import { useFetchObjectTypeDefinitions } from '../../../crmObjects/hooks/useFetchObjectTypeDefinitions';
import { LIST } from '../../../views/constants/PageType';
import Raven from 'raven-js';
import { getQuickFetchErrorsFromWindow, clearQuickFetchErrorsFromWindow } from '../../../quick-fetch/quickFetchErrorsOnWindow';
import { useCurrentViewId } from '../../../views/hooks/useCurrentViewId';
import { Metrics } from '../../../lib/metrics/metrics';
import { useFetchAppSettings } from '../../../frameworkAppSettings/hooks/useFetchAppSettings';
import { useFetchPipelines } from '../../pipelines/hooks/useFetchPipelines';
import { useFetchDefaultView } from '../../defaultView/hooks/useFetchDefaultView';
const trackErrors = () => {
  // Track any errors we encountered during the quick-fetches, except timeouts as we can't fix those.
  getQuickFetchErrorsFromWindow().forEach(error => {
    const status = getIn(['xhttp', 'status'], error);
    if (status === 0) {
      return;
    }
    Raven.captureException(error, {
      tags: {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'string'.
        fromQuickFetch: true
      },
      extra: {
        response: getIn(['xhttp', 'response'], error),
        responseText: getIn(['xhttp', 'responseText'], error),
        responseURL: getIn(['xhttp', 'responseURL'], error),
        status
      }
    });
  });
  clearQuickFetchErrorsFromWindow();
};
export const useFetchAllData = () => {
  const alreadyTrackedErrorRef = useRef(false);
  const objectTypeId = useSelectedObjectTypeId();
  const propertiesFetchStatus = useFetchAllProperties();
  const {
    data: appSettings,
    loading: appSettingsLoading
  } = useFetchAppSettings();
  const {
    data: objectTypes,
    loading: objectTypesLoading,
    error: objectTypersError
  } = useFetchObjectTypeDefinitions();
  const {
    data: pipelines,
    loading: pipelinesLoading,
    error: pipelinesError
  } = useFetchPipelines(objectTypeId);
  const currentViewId = useCurrentViewId();
  const {
    data: defaultView,
    loading: defaultViewLoading,
    error: defaultViewError
  } = useFetchDefaultView({
    objectTypeId,
    viewId: currentViewId
  });
  const pageType = useCurrentPageType();
  usePrefetchTableDeps({
    objectTypeId,
    skip: pageType !== LIST
  });
  const propertiesError = propertiesFetchStatus === FAILED ? new Error('Properties fetch failed') : undefined;
  const error = defaultViewError || objectTypersError || pipelinesError || propertiesError;
  const loading = appSettingsLoading || objectTypesLoading || pipelinesLoading || defaultViewLoading || [propertiesFetchStatus].some(status => [UNINITIALIZED, PENDING].includes(status));
  useEffect(() => {
    if (!loading || error) {
      if (defaultViewError) {
        Metrics.counter('missing-view-error').increment();
      } else if (!alreadyTrackedErrorRef.current) {
        trackErrors();
        alreadyTrackedErrorRef.current = true;
      }
    }
  }, [loading, error, defaultViewError]);
  return {
    appSettings,
    objectTypes,
    pipelines,
    loading,
    error,
    escapeHatch: {
      currentView: defaultView === null || defaultView === void 0 ? void 0 : defaultView.defaultView,
      currentViewError: defaultViewError
    }
  };
};