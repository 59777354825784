import { useEffect, useState } from 'react';
import { treatmentsPromise } from '../laboratoryClient';
import { useUserId } from '../../auth/hooks/useUserId';
const CRMI_0001_TREATMENT_KEY = 'crmi-0001';
const CRMI_0001_VARIANT = 'variant';
export const useIsCrmi0001Variant = () => {
  const [isInExperiment, setIsInExperiment] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const userId = useUserId();
  useEffect(() => {
    treatmentsPromise(userId).then(treatments => {
      var _treatments$CRMI_;
      setIsInExperiment((treatments === null || treatments === void 0 || (_treatments$CRMI_ = treatments[CRMI_0001_TREATMENT_KEY]) === null || _treatments$CRMI_ === void 0 || (_treatments$CRMI_ = _treatments$CRMI_.parameters) === null || _treatments$CRMI_ === void 0 ? void 0 : _treatments$CRMI_.group) === CRMI_0001_VARIANT);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, [userId]);
  return {
    isInExperiment,
    isLoading
  };
};