import get from 'transmute/get';
import { useScopes } from '../../auth/hooks/useScopes';
import { usePojoProperties } from '../../rewrite/properties/hooks/useProperties';
// @ts-expect-error untyped file
import { isVisibleGridProperty } from 'crm_data/properties/GridProperties';
import { AssociationColumnRegex } from '../../associations/utils/associationIdUtils';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import memoizeOne from 'react-utils/memoizeOne';
import { useTagPropertyName } from '../../board/hooks/useTagPropertyName';
import { useHasTagsPropertyRead } from '../../board/hooks/useHasTagsPropertyRead';
const generateIsVisibleGridColumnName = memoizeOne((objectTypeId, properties, tagPropertyName, hasTagsPropertyRead, scopes) => propertyName => {
  // HACK: Contacts have a 'name' column that is not a real or magic property
  if (objectTypeId === CONTACT_TYPE_ID && propertyName === 'name') {
    return true;
  }
  const isAssociationColumn = AssociationColumnRegex.test(propertyName);
  if (isAssociationColumn) {
    return true;
  }
  const property = get(propertyName, properties);
  if (!property) {
    return false;
  }
  if (propertyName === tagPropertyName && !hasTagsPropertyRead) {
    return false;
  }
  return isVisibleGridProperty(scopes, property);
});
export const useIsVisibleGridColumnName = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const properties = usePojoProperties();
  const tagPropertyName = useTagPropertyName();
  const hasTagsPropertyRead = useHasTagsPropertyRead();
  const scopes = useScopes();
  return generateIsVisibleGridColumnName(objectTypeId, properties, tagPropertyName, hasTagsPropertyRead, scopes);
};