import { gate } from 'hub-http/gates';
import { CRM_TAGS_SETTINGS, CRM_INDEX_PAGE_APP_SETTING } from '../constants/AppSettings';
const FALLBACK_INDEX_PAGE_APP_SETTINGS = {
  enabled: {
    value: true,
    source: CRM_INDEX_PAGE_APP_SETTING,
    isPortalSpecific: true
  },
  hasBoardView: {
    value: true,
    source: CRM_INDEX_PAGE_APP_SETTING,
    isPortalSpecific: true
  },
  hasExport: {
    value: true,
    source: CRM_INDEX_PAGE_APP_SETTING,
    isPortalSpecific: true
  },
  hasBulkActions: {
    value: true,
    source: CRM_INDEX_PAGE_APP_SETTING,
    isPortalSpecific: true
  },
  supportedVisualizations: {
    value: [{
      name: 'list'
    }, {
      name: 'board'
    }, {
      name: 'report',
      gates: [gate('CRM:Index:ShowReportPage')]
    }],
    source: CRM_INDEX_PAGE_APP_SETTING,
    isPortalSpecific: true
  }
};
export const getCustomObjectFallbackAppSettings = () => ({
  [CRM_TAGS_SETTINGS]: {
    metadata: {
      accessGates: {
        source: CRM_TAGS_SETTINGS,
        value: [],
        isPortalSpecific: true
      },
      accessScopes: {
        value: ['custom-object-access', 'crm-pipelines-object-label-access'],
        source: CRM_TAGS_SETTINGS,
        isPortalSpecific: true
      },
      propertyName: {
        value: 'hs_tag_ids',
        source: CRM_TAGS_SETTINGS,
        isPortalSpecific: true
      },
      enabled: {
        value: true,
        source: CRM_TAGS_SETTINGS,
        isPortalSpecific: true
      }
    }
  },
  [CRM_INDEX_PAGE_APP_SETTING]: {
    metadata: FALLBACK_INDEX_PAGE_APP_SETTINGS
  }
});