import { useSelectedObjectTypeDef } from '../../../crmObjects/hooks/useSelectedObjectTypeDef';
import { getIsPortalSpecific } from '../../../crmObjects/methods/getIsPortalSpecific';
import { useSupportedVisualizations } from '../../../viz/hooks/useSupportedVisualizations';
import { BOARD } from '../../../views/constants/PageType';
export const useHasPipelinesAndBoardView = () => {
  const typeDef = useSelectedObjectTypeDef();
  const supportedVisualizations = useSupportedVisualizations();
  const isPortalSpecific = typeDef ? getIsPortalSpecific(typeDef) : false;
  const hasBoardView = supportedVisualizations.has(BOARD);
  return (typeDef === null || typeDef === void 0 ? void 0 : typeDef.hasPipelines) && (isPortalSpecific || hasBoardView);
};