import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { setReconciledObjectsAction } from '../actions/localMutationsActions';
import { useSelectedObjectTypeDef } from '../../../crmObjects/hooks/useSelectedObjectTypeDef';
import { useApolloCacheActions } from '../../../apollo/hooks/useApolloCacheActions';
export const useLocalMutationsActions = () => {
  const dispatch = useDispatch();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'objectTypeId' does not exist on type 'un... Remove this comment to see the full error message
  const {
    objectTypeId,
    pipelineStagePropertyName
  } = useSelectedObjectTypeDef();
  const {
    updateObjects
  } = useApolloCacheActions();
  const setReconciledObjects = useCallback(({
    objectIdsToStageIds
  }) => {
    // This is an optimization that prevents objects from appearing to jump around
    // the screen when clearing a search. This unfortunately cannot fully take the place
    // of reconciliation because we would also need to update every cached query that
    // contains this object.
    // HACK: This is only safe because we *always* fetch the stage property. We need
    // to find a way to safely update Apollo's cache. Perhaps by using graphql mutations
    // for the single-object case?
    // The code that enforces fetching the property is here: https://git.hubteam.com/HubSpot/crm-index-ui/blob/0138bab59779e788280adcf3b7e196a60f88fec9/crm-index-ui/static/js/rewrite/searchQuery/hooks/useQueryProperties.js#L32
    // If we ever don't fetch the property, updateObjects will throw an invariant (crashing the page)
    // to make it very obvious.
    Object.entries(objectIdsToStageIds).forEach(([objectId, stageId]) => {
      updateObjects({
        objectIds: [objectId],
        propertyUpdates: {
          [pipelineStagePropertyName]: stageId
        }
      });
    });
    dispatch(setReconciledObjectsAction({
      objectTypeId,
      objectIdsToStageIds,
      pipelineStagePropertyName
    }));
  }, [dispatch, objectTypeId, pipelineStagePropertyName, updateObjects]);
  return {
    setReconciledObjects
  };
};