import { COMPANY, CONTACT, DEAL } from 'customer-data-objects/constants/ObjectTypes';
import { useHasCreatedObject } from '../../growth/hooks/useHasCreatedObject';
export const useIsEmptyListOrBoard = objectType => {
  const {
    loading: hasCreatedObjectLoading,
    data: hasCreatedObject
  } = useHasCreatedObject(objectType);
  if (objectType === COMPANY || objectType === DEAL || objectType === CONTACT) {
    return {
      isEmptyPageLoading: hasCreatedObjectLoading,
      isEmptyPage: hasCreatedObject !== undefined ? !hasCreatedObject : false
    };
  }
  return {
    isEmptyPageLoading: false,
    isEmptyPage: false
  };
};