import { CrmIndexSearchQuery } from '../../crmSearch/hooks/useCrmSearchQuery';
import { useSearchQuery } from '../../searchQuery/hooks/useSearchQuery';
import { useLazyQuery } from '@apollo/client';
import { IGNORED_GQL_ERRORS } from '../../../constants/ignoredGqlErrors';
export const useTableQueryAsync = () => {
  const {
    count,
    filterGroups,
    objectTypeId,
    offset,
    query: searchQuery,
    requestOptions = {
      properties: []
    },
    sorts
  } = useSearchQuery();
  const fetchPolicy = 'cache-and-network';
  const nextFetchPolicy = 'cache-first';
  const [fetch] = useLazyQuery(CrmIndexSearchQuery, {
    fetchPolicy,
    nextFetchPolicy,
    variables: {
      count,
      filterGroups,
      objectTypeId,
      offset,
      properties: requestOptions.properties,
      query: searchQuery,
      sorts
    },
    context: {
      disableGlobalErrorReportingForMatches: IGNORED_GQL_ERRORS
    }
  });
  return fetch;
};