import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import once from 'transmute/once';
import { usePinnedViewIdsWithCurrentView } from '../../rewrite/pinnedViews/hooks/usePinnedViewIdsWithCurrentView';
import { usePinnedViewLimit } from '../../rewrite/pinnedViews/hooks/usePinnedViewLimit';
import { trackIndexPageView, LOG_INDEX_PAGE_VIEW_TYPES, PAGE_VIEW_TO_VIEW_TYPE_MAP } from '../../tracking/indexPageTracking';
import { getPinnedViewIds } from '../../rewrite/pinnedViews/selectors/pinnedViewsSelectors';
import { commerceAnalyticsDataBinding } from 'growth-payments-core/hooks/useCommerceAnalyticsData';
import { getNetworkBindingData } from 'growth-payments-core/binding/getNetworkBindingData';
import { INVOICE_TYPE_ID, SUBSCRIPTION_TYPE_ID, PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import Raven from 'raven-js';
const trackIndexPageViewOnce = 'jasmine' in window ? trackIndexPageView : once(trackIndexPageView);
const shouldTrackCommerceAnalytics = objectTypeId => [INVOICE_TYPE_ID, SUBSCRIPTION_TYPE_ID, PRODUCT_TYPE_ID].includes(objectTypeId);

/**
 * @function useTrackIndexPageViewOnLoad
 * @param {PageType} props.pageType
 * @param {Object} props.typeDef
 */
export const useTrackIndexPageViewOnLoad = ({
  pageType,
  typeDef
}) => {
  const viewIds = usePinnedViewIdsWithCurrentView();
  const pinnedViewsLimit = usePinnedViewLimit();
  const numberOfPinnedViews = viewIds.length;
  // Use the selector directly to avoid fallback logic, we want to know if the
  // pinned views are loaded
  const pinnedViewIds = useSelector(getPinnedViewIds);
  const getCommerceAnalyticsData = () => getNetworkBindingData(commerceAnalyticsDataBinding);

  /**
   * Pinned views are lazily loaded now. We need to wait for them to load before tracking.
   */
  useEffect(() => {
    if (!pinnedViewIds.length) {
      return;
    }
    const _trackIndexPageViewOnce = extraProps => {
      trackIndexPageViewOnce(Object.assign({
        viewType: PAGE_VIEW_TO_VIEW_TYPE_MAP[pageType] || LOG_INDEX_PAGE_VIEW_TYPES.LIST_VIEW,
        typeDef,
        pinnedViewsLimit,
        numberOfPinnedViews
      }, extraProps));
    };

    // Fetch and track commerce analytics data if the object type is subscription, invoice, or product
    const trackIndexPageDataGetter = shouldTrackCommerceAnalytics(typeDef.objectTypeId) ? getCommerceAnalyticsData : () => Promise.resolve({});
    trackIndexPageDataGetter().then(({
      isEnrolled,
      isActivated,
      processorType
    }) => {
      _trackIndexPageViewOnce({
        isEnrolled,
        isActive: isActivated,
        processorType
      });
    }).catch(error => {
      Raven.captureException(error);
      // Fallback to default tracking in case commerce analytics getter fails
      _trackIndexPageViewOnce();
    });
  }, [pageType, pinnedViewIds, typeDef, pinnedViewsLimit, numberOfPinnedViews]);
};