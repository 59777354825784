import { createReducer } from '@reduxjs/toolkit';
import { createdCrmObjectAction, deleteCrmObjectsSucceededAction } from '../../crmObjects/actions/crmObjectsActions';
import { FILTERS_CHANGED, OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID, SORT_CHANGED } from '../../../views/actions/viewsActionTypes';
import { SYNC_ROUTER_VALUES } from '../../init/actions/initActionTypes';
import { pipelineChanged } from '../../pipelines/slice/currentPipelineIdSlice';
import { SYNC_SEARCH_TERM } from '../../search/actions/searchActionTypes';
export const initialState = {
  createdObjectIds: {},
  deletedObjectIds: {}
};
const addValuesWithoutDuplicates = (arr, ...values) => {
  const set = new Set([...(arr !== null && arr !== void 0 ? arr : []), ...values]);
  return Array.from(set);
};
export const localCrmObjectMutationsReducer = createReducer(initialState, builder => {
  builder.addCase(createdCrmObjectAction, (state, action) => {
    const {
      objectTypeId,
      objectId
    } = action.payload;
    const createdObjectIds = addValuesWithoutDuplicates(state.createdObjectIds[objectTypeId], objectId);
    state.createdObjectIds[objectTypeId] = createdObjectIds;
  }).addCase(deleteCrmObjectsSucceededAction, (state, action) => {
    const {
      objectTypeId,
      objectIds
    } = action.payload;
    state.deletedObjectIds[objectTypeId] = addValuesWithoutDuplicates(state.deletedObjectIds[objectTypeId], ...objectIds);
  }).addMatcher(action => {
    return [pipelineChanged.toString(), SORT_CHANGED, FILTERS_CHANGED, SYNC_SEARCH_TERM, OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID, SYNC_ROUTER_VALUES].includes(action.type);
  }, state => {
    state.createdObjectIds = {};
    state.deletedObjectIds = {};
  }).addDefaultCase(state => state);
});