module.exports = {
  "res-0010": {
    "identifierType": "PORTAL_ID",
    "maxAge": 86400000,
    "parameters": {
      "group": [
        "control",
        "variation"
      ]
    }
  },
  "dig-dig": {
    "identifierType": "PORTAL_ID",
    "maxAge": 0,
    "parameters": {
      "group": [
        "control",
        "variantA"
      ]
    }
  },
  "onbc-0017": {
    "identifierType": "PORTAL_ID",
    "maxAge": 86400000,
    "parameters": {
      "group": [
        "control",
        "variation"
      ]
    }
  },
  "crmi-0001": {
    "identifierType": "PORTAL_USER",
    "maxAge": 86400000,
    "parameters": {
      "group": [
        "control",
        "variant"
      ]
    }
  }
};