import { configureStore as createStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { authReducer } from '../../../auth/reducers/authReducer';
import { columnWidthsReducer } from '../../columnWidths/slice/columnWidthsSlice';
import { crmObjectsReducer } from '../../crmObjects/reducers/crmObjectsReducer';
import { fieldLevelPermissionsReducer } from '../../fieldLevelPermissions/reducers/fieldLevelPermissionsReducer';
import { inlineEditingReducer } from 'crm-object-table/inlineEdit';
import { irisReducer } from '../../iris/reducers/irisReducer';
import { localCrmObjectMutationsReducer } from '../../localMutations/reducers/localCrmObjectMutationsReducer';
import { overlayReducer } from '../../../overlay/reducers/overlayReducer';
import { paginationReducer } from '../../pagination/slice/paginationSlice';
import { pinnedViewsReducer } from '../../pinnedViews/reducers/pinnedViewsReducer';
import { currentPipelineIdReducer } from '../../pipelines/slice/currentPipelineIdSlice';
import { propertiesReducer } from '../../properties/reducers/propertiesReducer';
import { recentlyUsedPropertiesReducer } from '../../recentlyUsedProperties/slice/recentlyUsedPropertiesSlice';
import { routerStateReducer } from '../reducers/routerStateReducer';
import { searchReducer } from '../../search/reducers/searchReducer';
import { viewsReducer } from '../../../views/reducers/viewsReducer';

// TODO: When views are not immutable anymore we can just use the default middleware
export const configureStore = (preloadedState = {}) => createStore({
  middleware: [thunk],
  reducer: {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '<Base extends {}>(base?: Base | undefined, a... Remove this comment to see the full error message
    auth: authReducer,
    columnWidths: columnWidthsReducer,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '<Base extends {}>(base?: Base | undefined, a... Remove this comment to see the full error message
    crmObjects: crmObjectsReducer,
    currentPipelineId: currentPipelineIdReducer,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '<Base extends {}>(base?: Base | undefined, a... Remove this comment to see the full error message
    fieldLevelPermissions: fieldLevelPermissionsReducer,
    inlineEditing: inlineEditingReducer,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '<Base extends { readonly status: RequestStat... Remove this comment to see the full error message
    iris: irisReducer,
    localCrmObjectMutations: localCrmObjectMutationsReducer,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '<Base extends { readonly modalType: string; ... Remove this comment to see the full error message
    overlay: overlayReducer,
    pagination: paginationReducer,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Reducer<PinnedViewsStateType, PinnedViewsAct... Remove this comment to see the full error message
    pinnedViews: pinnedViewsReducer,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '<Base extends { readonly allPropertiesStatus... Remove this comment to see the full error message
    properties: propertiesReducer,
    recentlyUsedProperties: recentlyUsedPropertiesReducer,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '<Base extends { readonly currentObjectTypeId... Remove this comment to see the full error message
    routerState: routerStateReducer,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '<Base extends { readonly searchTerm: undefin... Remove this comment to see the full error message
    search: searchReducer,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '<Base extends { readonly data: {}; readonly ... Remove this comment to see the full error message
    views: viewsReducer
  },
  devTools: {
    name: 'crm-index-ui'
  },
  preloadedState
});