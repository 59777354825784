const _unique = definitions => {
  const names = {};
  return definitions.filter(definition => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }
    const name = definition.name.value;
    if (names[name]) {
      return false;
    } else {
      names[name] = true;
      return true;
    }
  });
};
import { useApolloClient, gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useLocalMutationsActions } from '../../localMutations/hooks/useLocalMutationsActions';
import getIn from 'transmute/getIn';
import { createdCrmObjectAction, getCrmObjectsStartedAction, getCrmObjectsSucceededAction, getCrmObjectsFailedAction, deleteCrmObjectsSucceededAction } from '../actions/crmObjectsActions';
import { CrmObjectFragment } from '../../crmSearch/hooks/useCrmSearchQuery';
import { mutableSetIn } from '../../objectUtils/mutableSetIn';
import { rewriteObjectPropertiesAsMap } from '../../crmSearch/utils/rewriteObjectPropertiesAsMap';
import { useQueryProperties } from '../../searchQuery/hooks/useQueryProperties';
import { useHasPipelinesAndBoardView } from '../../board/hooks/useHasPipelinesAndBoardView';
import { useSelectedObjectTypeDef } from '../../../crmObjects/hooks/useSelectedObjectTypeDef';

// FIXME: migrate to use type from customer-data-objects

export const GET_CRM_OBJECTS = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"GetCrmObjectsQuery\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}},{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectIds\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"ListType\",\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Long\"}}}}}},{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"properties\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"ListType\",\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"String\"}}}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"crmObjects\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"type\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectTypeId\"}}},{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"ids\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectIds\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"FragmentSpread\",\"name\":{\"kind\":\"Name\",\"value\":\"CrmObjectFragment\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: _unique([{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "GetCrmObjectsQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectTypeId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectIds"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "ListType",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "Long"
              }
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "properties"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "ListType",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "String"
              }
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "crmObjects"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "type"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectTypeId"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "ids"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectIds"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "CrmObjectFragment"
            }
          }]
        }
      }]
    }
  }].concat(CrmObjectFragment.definitions))
});
export const useCrmObjectsActions = () => {
  const dispatch = useDispatch();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'objectTypeId' does not exist on type 'un... Remove this comment to see the full error message
  const {
    objectTypeId: selectedObjectTypeId,
    pipelineStagePropertyName
  } = useSelectedObjectTypeDef();
  const {
    setReconciledObjects
  } = useLocalMutationsActions();
  const client = useApolloClient();
  const properties = useQueryProperties();
  const hasPipelinesAndBoardView = useHasPipelinesAndBoardView();
  const getCrmObjects = useCallback(({
    objectIds
  }) => {
    dispatch(getCrmObjectsStartedAction({
      objectTypeId: selectedObjectTypeId,
      objectIds
    }));
    return client.query({
      query: GET_CRM_OBJECTS,
      variables: {
        objectTypeId: selectedObjectTypeId,
        // @ts-expect-error TODO fix after client-types migration. This is relying on coercion.
        objectIds,
        properties
      }
    }).then(results => {
      // HACK: Unfortunately we have to preserve the format here from before we fetched this data
      // via Apollo. When we remove the redux slice that manages this data, we can also ditch this
      // transformation (and all dispatches in this callback)
      const crmObjects = results && results.data && results.data.crmObjects ? results.data.crmObjects : [];
      const objects = crmObjects.reduce((objectsById, object) => {
        mutableSetIn([object.objectId], rewriteObjectPropertiesAsMap(object), objectsById);
        return objectsById;
      }, {});
      dispatch(getCrmObjectsSucceededAction({
        objectTypeId: selectedObjectTypeId,
        objectIds,
        objects
      }));
      return objects;
    }).catch(error => {
      dispatch(getCrmObjectsFailedAction({
        objectTypeId: selectedObjectTypeId,
        objectIds
      }));
      throw error;
    });
  }, [client, dispatch, properties, selectedObjectTypeId]);
  const crmObjectCreated = useCallback(({
    objectTypeId = selectedObjectTypeId,
    objectId
  }) => {
    // This can be called for any arbitrary object type because the sidebar lets you edit/create associations.
    // However we only want to actually do something if the object type is currently selected,
    // because we clear the cache when switching types.
    if (objectTypeId !== selectedObjectTypeId) {
      return Promise.resolve();
    }
    return getCrmObjects({
      objectIds: [objectId]
    }).then(objects => {
      dispatch(createdCrmObjectAction({
        objectTypeId,
        objectId
      }));
      if (hasPipelinesAndBoardView) {
        setReconciledObjects({
          objectIdsToStageIds: {
            [objectId]: getIn([objectId, 'properties', pipelineStagePropertyName, 'value'], objects)
          }
        });
      }
    });
  }, [dispatch, getCrmObjects, hasPipelinesAndBoardView, pipelineStagePropertyName, selectedObjectTypeId, setReconciledObjects]);
  const crmObjectsDeleted = useCallback(({
    objectTypeId = selectedObjectTypeId,
    objectIds
  }) => dispatch(deleteCrmObjectsSucceededAction({
    objectTypeId,
    objectIds
  })), [dispatch, selectedObjectTypeId]);
  return {
    crmObjectCreated,
    getCrmObjects,
    crmObjectsDeleted
  };
};