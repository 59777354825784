import { getCurrentObjectTypeId } from '../../init/selectors/routerStateSelectors';
import { createFrozenSelector } from '../../utils/createFrozenSelector';
import get from 'transmute/get';
import { createSelector } from 'reselect';
const getLocalMutationsSlice = state => state.localCrmObjectMutations;
export const getLocallyCreatedObjectIds = createFrozenSelector([getLocalMutationsSlice], slice => slice.createdObjectIds);
export const getLocallyDeletedObjectIds = createFrozenSelector([getLocalMutationsSlice], slice => slice.deletedObjectIds);
export const getLocallyCreatedObjectIdsForCurrentType = createFrozenSelector([getLocallyCreatedObjectIds, getCurrentObjectTypeId], (idMap, objectTypeId) => get(objectTypeId, idMap) || []);
export const getLocallyCreatedObjectIdsForCurrentTypeAsNumber = createSelector([getLocallyCreatedObjectIds, getCurrentObjectTypeId], (createdObjectIds, objectTypeId) => {
  var _createdObjectIds$obj, _createdObjectIds$obj2;
  return (_createdObjectIds$obj = (_createdObjectIds$obj2 = createdObjectIds[objectTypeId]) === null || _createdObjectIds$obj2 === void 0 ? void 0 : _createdObjectIds$obj2.map(Number)) !== null && _createdObjectIds$obj !== void 0 ? _createdObjectIds$obj : [];
});
export const getLocallyDeletedObjectIdsForCurrentType = createFrozenSelector([getLocallyDeletedObjectIds, getCurrentObjectTypeId], (idMap, objectTypeId) => get(objectTypeId, idMap) || []);