// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { isOfMinSearchLength } from 'crm_data/elasticSearch/ElasticSearchValidation';
import { RESTORE_CACHED_VALUES } from '../../init/actions/initActionTypes';
import { produce } from 'immer';
import { SYNC_SEARCH_TERM } from '../actions/searchActionTypes';
const initialState = {
  searchTerm: '',
  lastValidSearchTerm: ''
};
export const searchReducer = produce((draft, action) => {
  switch (action.type) {
    case RESTORE_CACHED_VALUES:
    case SYNC_SEARCH_TERM:
      {
        const {
          searchTerm
        } = action.payload;
        const isValid = isOfMinSearchLength(searchTerm) || !searchTerm;
        draft.searchTerm = searchTerm;
        if (isValid) {
          draft.lastValidSearchTerm = searchTerm;
        }
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}, initialState);