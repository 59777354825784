import { useEffect } from 'react';
import { trackIndexPageViewDynamic } from '../../tracking/indexPageTracking';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useCurrentViewId } from '../../views/hooks/useCurrentViewId';
import { useCurrentPageType } from '../../views/hooks/useCurrentPageType';
import { getStoredAiViewData } from 'customer-data-views-management/utils/aiViewStorage';
import { useCurrentView } from '../../views/hooks/useCurrentView';
import { isHubspotStandardView } from 'customer-data-views-management/singleView/dataOperators/isHubspotStandardView';
let calls = [];

//this will prevent a double amplitude track even if IndexPage is rerendered
const memoizedTracker = ({
  viewId,
  objectTypeId,
  pageType,
  isAiGenerated,
  isStandardView
}) => {
  const cacheKey = `${objectTypeId}-${viewId}-${pageType}`;
  if (calls[calls.length - 1] === cacheKey) {
    return;
  }
  trackIndexPageViewDynamic({
    objectTypeId,
    viewId,
    pageType,
    isAiGenerated,
    isStandardView
  });
  calls.push(cacheKey);
};
export const __unit_tests_only_reset_trackIndexPageViewDynamic_cache = () => calls = [];

/**
 * useTrackIndexPageViewDynamic will log a indexInteraction event any time the objectTypeId/viewId/pageType changes
 *
 * It differs from useTrackIndexPageViewOnLoad, as useTrackIndexPageViewOnLoad only emits an event once on app init, and never again per session
 *
 * It will internally memoize itself to prevent double tracking of the same event regardless of if the consumer rerenders
 */
export const useTrackIndexPageViewDynamic = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const viewId = useCurrentViewId();
  const viewType = useCurrentPageType();
  const currentView = useCurrentView();
  const isAiGenerated = Boolean(viewId && getStoredAiViewData(objectTypeId, viewId));
  const isStandardView = Boolean(currentView && isHubspotStandardView({
    view: currentView
  }));
  useEffect(() => {
    if (objectTypeId && viewId && viewType) {
      memoizedTracker({
        viewId,
        pageType: viewType,
        objectTypeId,
        isAiGenerated,
        isStandardView
      });
    }
  }, [objectTypeId, viewId, viewType, isAiGenerated, isStandardView]);
};