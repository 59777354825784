import { createClient } from 'laboratory-lib';
import PortalIdParser from 'PortalIdParser';
import experiments from '../../experiments.yaml';
let laboratoryClient;
export const createLaboratoryClient = userId => {
  if (!laboratoryClient) {
    const portalId = PortalIdParser.get();
    laboratoryClient = createClient({
      quickFetchLabel: 'experiment-treatments',
      experiments: experiments,
      identifiers: {
        portalId,
        portalUserId: `${portalId}:::${userId}`
      }
    });
    laboratoryClient.resolve();
  }
  return laboratoryClient;
};
export const getLaboratoryClient = userId => {
  var _laboratoryClient;
  return (_laboratoryClient = laboratoryClient) !== null && _laboratoryClient !== void 0 ? _laboratoryClient : createLaboratoryClient(userId);
};
export const treatmentsPromise = userId => new Promise(getLaboratoryClient(userId).resolve);