import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import { ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
const EMPTY_STATE_ELIGIBLE_OBJECT_TYPES = ['COMPANY', 'DEAL', 'CONTACT'];
export const GET_HAS_CREATED_OBJECT = registerQuery({
  fieldName: 'hasCreatedObject',
  args: ['objectTypeId'],
  fetcher: ({
    objectTypeId
  }) => http.get(`growth-usage/v1/portal-usage/has-created-object/${objectTypeId}`)
});
export const useHasCreatedObject = objectType => {
  var _data$hasCreatedObjec;
  const objectTypeId = ObjectTypesToIds[objectType];
  const _useQuery = useQuery(GET_HAS_CREATED_OBJECT, {
      variables: {
        objectTypeId
      },
      skip: !EMPTY_STATE_ELIGIBLE_OBJECT_TYPES.includes(objectType)
    }),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return Object.assign({
    data: data === null || data === void 0 || (_data$hasCreatedObjec = data.hasCreatedObject) === null || _data$hasCreatedObjec === void 0 ? void 0 : _data$hasCreatedObjec.hasCreated
  }, rest);
};