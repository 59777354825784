import { produce } from 'immer';
import { PENDING, SUCCEEDED, FAILED } from '../../constants/RequestStatus';
import { PROPERTIES_FETCH_STARTED, PROPERTIES_FETCH_SUCCEEDED, PROPERTIES_FETCH_FAILED, PROPERTIES_OPTIMISTICALLY_UPDATED } from '../actions/propertiesActionTypes';
const initialState = {
  allPropertiesStatus: {},
  data: {}
};
export const propertiesReducer = produce((draft, action) => {
  switch (action.type) {
    case PROPERTIES_FETCH_STARTED:
      {
        const {
          objectTypeId
        } = action.payload;
        draft.allPropertiesStatus[objectTypeId] = PENDING;
        return draft;
      }
    case PROPERTIES_FETCH_SUCCEEDED:
      {
        const {
          properties,
          objectTypeId
        } = action.payload;
        draft.allPropertiesStatus[objectTypeId] = SUCCEEDED;
        draft.data[objectTypeId] = properties.reduce((allProperties, {
          property
        }) => {
          allProperties[property.name] = property;
          return allProperties;
        }, {});
        return draft;
      }
    case PROPERTIES_FETCH_FAILED:
      {
        const {
          objectTypeId
        } = action.payload;
        draft.allPropertiesStatus[objectTypeId] = FAILED;
        return draft;
      }
    case PROPERTIES_OPTIMISTICALLY_UPDATED:
      {
        const {
          properties,
          objectTypeId
        } = action.payload;
        for (const property of properties) {
          draft.data[objectTypeId][property.name] = property;
        }
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}, initialState);