import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { syncRouterValuesAction } from '../actions/initActions';
import { useQueryParams } from '../../../router/useQueryParams';
import { useSelectedObjectTypeId } from '../../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useCurrentViewId } from '../../../views/hooks/useCurrentViewId';
import { useCurrentPageType } from '../../../views/hooks/useCurrentPageType';
import { useSearchTerm } from '../../search/hooks/useSearchTerm';
import { syncSearchTermAction } from '../../search/actions/searchActions';
import { getUIState } from '../../../crm_ui/grid/utils/gridStateLocalStorage';
import get from 'transmute/get';
export const useSyncRouterValuesToRedux = ({
  isRestoreRoute = false
} = {}) => {
  const dispatch = useDispatch();

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'objectTypeId' does not exist on type '{}... Remove this comment to see the full error message
  const {
    objectTypeId,
    viewId: routeViewId,
    pageType
  } = useParams();
  const {
    query
  } = useQueryParams();
  const lastAccessedViewId = useMemo(() => get('viewId', getUIState(objectTypeId)), [objectTypeId]);
  const viewId = isRestoreRoute ? lastAccessedViewId : routeViewId;
  const syncedTypeId = useSelectedObjectTypeId();
  const syncedViewId = useCurrentViewId();
  const syncedPageType = useCurrentPageType();
  const syncedSearchTerm = useSearchTerm();
  const shouldSyncPathParams = syncedTypeId !== objectTypeId || syncedViewId !== viewId || syncedPageType !== pageType;
  const shouldSyncSearchTerm = syncedSearchTerm !== query;
  useEffect(() => {
    if (shouldSyncPathParams) {
      dispatch(syncRouterValuesAction({
        objectTypeId,
        viewId,
        pageType
      }));
    }
    if (shouldSyncSearchTerm) {
      //`query` is the "query" url query parameter. There can be multiple parameters of the same name.
      //If there are multiple "query" url parameters, we will take the first
      const normalizedQueryParam = Array.isArray(query) ? query[0] : query;

      //we do not want to set the queryParam to undefined, see https://git.hubteam.com/HubSpot/crm-index-ui/pull/4743/files,
      //but if its undefined, it means the app is trying to "reset" it, so we still want to set it to an empty string
      const nonEmptyQueryParam = normalizedQueryParam === undefined ? '' : normalizedQueryParam;
      dispatch(syncSearchTermAction(nonEmptyQueryParam));
    }
  }, [dispatch, objectTypeId, pageType, query, shouldSyncPathParams, shouldSyncSearchTerm, viewId]);

  // This is the minimum data that is required to begin the init flow
  return syncedTypeId === objectTypeId;
};