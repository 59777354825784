import produce from 'immer';
import { mutableUpdateIn } from '../../objectUtils/mutableUpdateIn';
const MULTI_ENUM_DELIMITER = ';';
const APPEND_MULTI_ENUM_DELIMITER = ';';
const REMOVE_MULTI_ENUM_DELIMITER = '?^';
// Multi-enum properties are a set of options split by semicolons.
//
// They can be edited in multiple strategies: "append", "replace", "remove"
// (this append/replace/remove functionality can be found in the bulk edit modal, example property "buying role")
//
// "replace" will discard all values from the previous array (split at semicolon)
// "append" will combine the previous value + new value into a deduped array (split at semicolon)
// "remove" will remove the previous value + new value into a deduped array (split at semicolon)
//
// The backend determines "append", "remove", "replace" by the first character.
// If the first character of the new value is a semicolon, it "appends" the values.
// If the first character of the new value is a '?^', it "removes" the values.
// If the first character of the new value is anything except a semicolon, it "replaces" the values
//
// This function is meant to re-create that functionality on the frontend within our optimistic update code
// Multi-enum properties are a set of options split by semicolons.
//
// They can be edited in multiple strategies: "append", "replace", "remove"
// (this append/replace/remove functionality can be found in the bulk edit modal, example property "buying role")
//
// "replace" will discard all values from the previous array (split at semicolon)
// "append" will combine the previous value + new value into a deduped array (split at semicolon)
// "remove" will remove the previous value + new value into a deduped array (split at semicolon)
//
// The backend determines "append", "remove", "replace" by the first character.
// If the first character of the new value is a semicolon, it "appends" the values.
// If the first character of the new value is a '?^', it "removes" the values.
// If the first character of the new value is anything except a semicolon, it "replaces" the values
//
// This function is meant to re-create that functionality on the frontend within our optimistic update code
function getNewPropertyValue(currentValue, propertyUpdate) {
  propertyUpdate = String(propertyUpdate);

  // If it starts with a semicolon, we should append the values.
  if (propertyUpdate.startsWith(APPEND_MULTI_ENUM_DELIMITER)) {
    const newValues = new Set();

    // Add current values to the set only if they exist, preventing
    // undefined values from being added and resulting in a final value
    // prepended by "undefined", e.g. "undefined;new-appended-value".
    // @see https://issues.hubspotcentral.com/browse/CRM-58161
    currentValue === null || currentValue === void 0 || currentValue.split(MULTI_ENUM_DELIMITER).forEach(val => newValues.add(val));
    propertyUpdate.split(MULTI_ENUM_DELIMITER).filter(Boolean) //when .split ing the first item in the array will be a '' so we filter that out
    //avoid inlinining currentValues.add https://stackoverflow.com/questions/37199019/method-set-prototype-add-called-on-incompatible-receiver-undefined
    .forEach(newValue => newValues.add(newValue));
    return Array.from(newValues).join(MULTI_ENUM_DELIMITER);
  }
  if (String(propertyUpdate).startsWith(REMOVE_MULTI_ENUM_DELIMITER)) {
    //if it starts with '?^' we should remove the values
    const newValues = new Set();

    // Add current values to the set only if they exist, preventing
    // undefined values from being added and resulting in a final value
    // prepended by "undefined", e.g. "undefined;new-appended-value".
    // @see https://issues.hubspotcentral.com/browse/CRM-58161
    currentValue === null || currentValue === void 0 || currentValue.split(MULTI_ENUM_DELIMITER).forEach(val => newValues.add(val));
    propertyUpdate.replace(/^[?^]+/, '') // removes any leading ? or ^ characters from the start of a string
    .split(MULTI_ENUM_DELIMITER).forEach(value => newValues.delete(value));
    return Array.from(newValues).join(MULTI_ENUM_DELIMITER);
  }

  //if it does not start with a semicolon we just replace the values
  return propertyUpdate;
}
export const mergeUpdatesIntoCrmObject = produce((draft, propertyUpdates) => {
  Object.entries(propertyUpdates).forEach(([propertyName, propertyUpdate]) => {
    mutableUpdateIn(['properties', propertyName, 'value'], currentValue => getNewPropertyValue(currentValue, propertyUpdate), draft);
  });
});