import { useMemo } from 'react';
import { useIndexPageAppSettingFieldValue } from '../../frameworkAppSettings/hooks/useIndexPageAppSettingFieldValue';
import { useHasAllScopes } from '../../auth/hooks/useHasAllScopes';
import { useHasAllGates } from '../../auth/hooks/useHasAllGates';
import { isValidPageType } from '../../views/constants/PageType';
const passesGates = (gates, hasGates) => {
  if (!(gates !== null && gates !== void 0 && gates.length)) {
    return true;
  }
  return hasGates(...gates);
};
const passesScopes = (scopes, hasScopes) => {
  if (!(scopes !== null && scopes !== void 0 && scopes.length)) {
    return true;
  }
  return hasScopes(...scopes);
};
export const useSupportedVisualizations = () => {
  const supportedVisualizations = useIndexPageAppSettingFieldValue('supportedVisualizations');
  const hasGates = useHasAllGates();
  const hasScopes = useHasAllScopes();
  return useMemo(() => {
    const uniqueVisualizations = new Set(supportedVisualizations === null || supportedVisualizations === void 0 ? void 0 : supportedVisualizations.filter(visualizationConfig => {
      return passesGates(visualizationConfig.gates, hasGates) && passesScopes(visualizationConfig.scopes, hasScopes) && isValidPageType(visualizationConfig.name);
    }).map(visualizationConfig => visualizationConfig.name));
    return new Set([...uniqueVisualizations]);
  }, [hasGates, hasScopes, supportedVisualizations]);
};