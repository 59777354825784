import userInfo from 'hub-http/userInfo';
import experimentLangs from 'i2l?mode=very-lazy&query=sporks!../../lang/laboratory-copy-experiments/en.lyaml';
const loadI18nExperimentProvider = () => import( /* webpackChunkName: "experiment-langs" */'./getI18nExperimentProvider');
export async function i18nExperimentInit() {
  const {
    getI18nExperimentProvider
  } = await loadI18nExperimentProvider();
  const {
    user
  } = await userInfo();
  const experimentProvider = getI18nExperimentProvider(user.user_id);
  return experimentProvider.register(experimentLangs);
}
export async function setI18nExperimentLocale() {
  const {
    user,
    portal
  } = await userInfo();
  const {
    getI18nExperimentProvider
  } = await loadI18nExperimentProvider();
  const experimentProvider = getI18nExperimentProvider(user.user_id);
  experimentProvider.setLocale({
    locale: user.locale,
    langEnabled: user.lang_enabled,
    timezone: portal.timezone
  });
}