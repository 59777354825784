import { ActionsDropdown, Banner, DataWell, DataWellShowHideButton, EmptyState, PrimaryActionButton, QuickFilterProperties, SecondaryActionButton, TertiaryActionButton, ZeroQueryResultsState } from '../constants/BehaviorTypes';
import EditPropertiesButton from '../../header/actions/EditPropertiesButton';
import RecycleBinButton from '../../header/actions/RecycleBinButton';
import { ImportButtonWrapper } from '../../header/actions/ImportButton';
import { DefaultEmptyState } from '../../emptyStateNew/DefaultEmptyState';
import { DefaultZeroQueryResults } from '../../zeroQueryResults/DefaultZeroQueryResults';
import { DefaultBanner } from '../../banner/DefaultBanner';
import { CreateObjectWrapper } from '../../header/actions/CreateObjectWrapper';
export const EmptyButton = () => null;
const isValidQuickFilter = value => Boolean(value);
const getQuickFilterPropertiesFromTypeDef = ({
  typeDef: {
    primaryDisplayLabelPropertyName,
    createDatePropertyName,
    lastModifiedPropertyName,
    secondaryDisplayLabelPropertyNames
  }
}) => [...new Set([primaryDisplayLabelPropertyName, createDatePropertyName, lastModifiedPropertyName, ...secondaryDisplayLabelPropertyNames])].filter(isValidQuickFilter).slice(0, 4);
export const DefaultExtension = {
  [ActionsDropdown]: ({
    typeDef
  }) => [EditPropertiesButton, ...(typeDef.restorable ? [RecycleBinButton] : [])],
  [QuickFilterProperties]: getQuickFilterPropertiesFromTypeDef,
  [PrimaryActionButton]: () => CreateObjectWrapper,
  [SecondaryActionButton]: () => ImportButtonWrapper,
  [EmptyState]: () => DefaultEmptyState,
  [ZeroQueryResultsState]: () => DefaultZeroQueryResults,
  [TertiaryActionButton]: () => EmptyButton,
  [DataWell]: () => () => null,
  [DataWellShowHideButton]: () => () => null,
  [Banner]: () => DefaultBanner
};