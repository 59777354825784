import { useFrameworkAppSettings } from '../../frameworkAppSettings/context/AppSettingsContext';
import { CRM_RECORD_CREATOR_SETTINGS } from '../../frameworkAppSettings/constants/AppSettings';
import { getAppSettingForTypeDef, getFieldValue } from '../../frameworkAppSettings/utils/getFieldValue';
import { useSelectedObjectTypeDef } from '../../crmObjects/hooks/useSelectedObjectTypeDef';
import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
export const useIsObjectRecordCreatorEnabled = () => {
  var _getFieldValue;
  const appSettings = useFrameworkAppSettings();
  const typeDef = useSelectedObjectTypeDef();
  if (!typeDef) {
    return false;
  }
  const metadata = getAppSettingForTypeDef(appSettings, typeDef, CRM_RECORD_CREATOR_SETTINGS);
  return (_getFieldValue = getFieldValue(metadata, 'enabledInCreator')) !== null && _getFieldValue !== void 0 ? _getFieldValue : isPortalSpecificObjectType(typeDef.objectTypeId);
};