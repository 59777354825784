import Raven from 'raven-js';
import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
import { CRM, PRODUCTS, LISTS } from '../constants/namespaces';
export const trackerNamespaces = [CRM, LISTS, PRODUCTS];

/**
 * Most index page tracking is through customer-data-tracking and can be used
 * through methods in indexPageTracking.ts. This file creates a local tracker
 * to be used for local events we define or import into our events.yaml as we
 * migrate off of customer-data-tracking to local tracking.
 */

export const tracker = createTracker({
  events,
  properties: {
    namespace: CRM
  },
  onError: err => {
    Raven.captureMessage('usage-tracker-js event error', {
      extra: {
        message: err.message
      }
    });
  },
  lastKnownEventProperties: ['screen', 'subscreen', 'objectTypeId'],
  debug: false
});
const getTracker = (namespace = CRM) => {
  tracker.setProperties({
    namespace
  });
  return tracker;
};
export const getTrackerSendImmediate = (namespace = CRM) => getTracker(namespace).clone({
  bypassPool: true
});
const CrmUsageTracker = {
  trackerProperties: undefined,
  init: properties => {
    if (properties) CrmUsageTracker.trackerProperties = properties;
  },
  track: (evt, evtData, options = {}) => {
    const {
      sendImmediate = false,
      namespace = CRM
    } = options;
    const trackerInstance = sendImmediate ? getTrackerSendImmediate(namespace) : getTracker(namespace);
    if (CrmUsageTracker.trackerProperties) {
      trackerInstance.setProperties(CrmUsageTracker.trackerProperties);
    }
    trackerInstance.track(evt, evtData);
  }
};
export default CrmUsageTracker;