import { useCallback } from 'react';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useCrmObjectsActions } from './useCrmObjectsActions';
export const useSyncObjectCRUDState = () => {
  const {
    crmObjectCreated,
    crmObjectsDeleted
  } = useCrmObjectsActions();
  useOnCrmMessageTopic(TOPIC_NAMES.OBJECT_CREATED, args => {
    crmObjectCreated(args).catch(e => {
      throw e;
    });
  });
  const sendDeleteMessage = useCallback(({
    objectId: deletedObjectId,
    objectTypeId: deletedObjectTypeId
  }) => {
    crmObjectsDeleted({
      objectTypeId: deletedObjectTypeId,
      objectIds: [Number(deletedObjectId)]
    });
  }, [crmObjectsDeleted]);
  useOnCrmMessageTopic(TOPIC_NAMES.OBJECT_DELETED, sendDeleteMessage);
};