import { FILTERS_CHANGED, FILTER_GROUPS_CHANGED, QUICK_FILTERS_CHANGED, COLUMNS_CHANGED, RESET_VIEW, SAVE_VIEW_SUCCEEDED, SAVE_VIEW_STARTED, SAVE_VIEW_FAILED, SORT_CHANGED, STORE_VIEWS, REPLACE_VIEWS, OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID, QUICK_FILTER_PROPERTIES_CHANGED, DATAWELL_REPORT_ID_UPDATED } from '../actions/viewsActionTypes';
import hasIn from 'transmute/hasIn';
import filter from 'transmute/filter';
import { RESTORE_CACHED_VALUES, SYNC_ROUTER_VALUES } from '../../rewrite/init/actions/initActionTypes';
import { ALL_PIPELINES_VALUE } from '../../rewrite/pipelines/constants/AllPipelinesValue';
import getIn from 'transmute/getIn';
import { produce } from 'immer';
import { mutableSetIn } from '../../rewrite/objectUtils/mutableSetIn';
import { mutableDeleteIn } from '../../rewrite/objectUtils/mutableDeleteIn';
import { mutableUpdateIn } from '../../rewrite/objectUtils/mutableUpdateIn';
import { STANDARD } from 'customer-data-objects/view/ViewTypes';
import { pipelineChanged } from '../../rewrite/pipelines/slice/currentPipelineIdSlice';
import { Metrics } from '../../lib/metrics/metrics';
export const initialState = {
  data: {},
  cachedData: {},
  cachedDataBeforeSave: {},
  currentViewId: null,
  currentPageType: null,
  // HACK: This is used so that we do not sync deleted views back into redux.
  // Once we remove the CacheViews component in IndexDataLoader and refactor back to
  // caching in each individual query's `onCompleted`, this hack can be removed.
  // See https://hubspot.slack.com/archives/C016VNJ0B8E/p1652464756958209?thread_ts=1652461888.492609&cid=C016VNJ0B8E
  deletedViewIds: {}
};
export const viewsReducer = produce((draft, action) => {
  switch (action.type) {
    case SAVE_VIEW_STARTED:
      {
        const {
          objectTypeId,
          view
        } = action.payload;
        const {
          id: viewId,
          type
        } = view;
        const isCustomView = type === STANDARD;

        // HACK: When saving default views, the only thing that changes are the columns.
        // So we only persist the column changes into state to ensure that "reset"
        // works correctly for default views.
        // TODO: Find a better way to handle this. I'm sure there is one but I can't think of it now.
        const updatePath = isCustomView ? [objectTypeId, viewId] : [objectTypeId, viewId, 'columns'];
        const value = isCustomView ? view : view.columns;
        const currentState = getIn(['cachedData', objectTypeId, viewId], draft);
        mutableSetIn(['cachedDataBeforeSave', objectTypeId, viewId], currentState, draft);
        mutableSetIn(['cachedData', ...updatePath], value, draft);
        mutableSetIn(['data', ...updatePath], value, draft);
        return draft;
      }
    case SAVE_VIEW_SUCCEEDED:
      {
        const {
          objectTypeId,
          view
        } = action.payload;
        mutableDeleteIn(['cachedDataBeforeSave', objectTypeId, view.id], draft);
        return draft;
      }
    case SAVE_VIEW_FAILED:
      {
        const {
          objectTypeId,
          view
        } = action.payload;
        const {
          id: viewId
        } = view;
        const previousState = getIn(['cachedDataBeforeSave', objectTypeId, viewId], draft);
        mutableSetIn(['data', objectTypeId, viewId], previousState, draft);
        mutableSetIn(['cachedData', objectTypeId, viewId], previousState, draft);
        mutableDeleteIn(['cachedDataBeforeSave', objectTypeId, viewId], draft);
        return draft;
      }
    case SORT_CHANGED:
      {
        const {
          objectTypeId,
          viewId,
          sortKey,
          sortColumnName,
          order
        } = action.payload;
        mutableUpdateIn(['data', objectTypeId, viewId, 'state'], currentState => Object.assign({}, currentState, {
          sortKey,
          sortColumnName,
          order
        }), draft);
        return draft;
      }
    case DATAWELL_REPORT_ID_UPDATED:
      {
        const {
          objectTypeId,
          viewId,
          viewConfiguration
        } = action.payload;
        mutableUpdateIn(['data', objectTypeId, viewId, 'state'], currentState => Object.assign({}, currentState, {
          viewConfiguration
        }), draft);
        return draft;
      }
    case FILTERS_CHANGED:
      {
        const {
          objectTypeId,
          viewId,
          filters
        } = action.payload;
        mutableSetIn(['data', objectTypeId, viewId, 'filters'], filters, draft);
        return draft;
      }
    case FILTER_GROUPS_CHANGED:
      {
        const {
          objectTypeId,
          viewId,
          filterGroups
        } = action.payload;
        mutableSetIn(['data', objectTypeId, viewId, 'filterGroups'], filterGroups, draft);
        return draft;
      }
    case QUICK_FILTERS_CHANGED:
      {
        const {
          objectTypeId,
          viewId,
          quickFilters
        } = action.payload;
        mutableSetIn(['data', objectTypeId, viewId, 'quickFilters'], quickFilters, draft);
        return draft;
      }
    case QUICK_FILTER_PROPERTIES_CHANGED:
      {
        const {
          objectTypeId,
          viewId,
          quickFilterProperties
        } = action.payload;
        mutableSetIn(['data', objectTypeId, viewId, 'quickFilterProperties'], quickFilterProperties, draft);
        return draft;
      }
    case RESET_VIEW:
      {
        const {
          objectTypeId,
          viewId
        } = action.payload;
        mutableSetIn(['data', objectTypeId, viewId], getIn(['cachedData', objectTypeId, viewId], draft), draft);
        return draft;
      }
    case COLUMNS_CHANGED:
      {
        const {
          objectTypeId,
          viewId,
          columns,
          state
        } = action.payload;
        mutableSetIn(['data', objectTypeId, viewId, 'columns'], columns, draft);
        if (state && typeof state === 'object' && Object.keys(state).length) {
          mutableSetIn(['data', objectTypeId, viewId, 'state'], state, draft);
        } else if (state) {
          Metrics.counter('global-default-views-state-error').increment();
        }
        return draft;
      }
    case SYNC_ROUTER_VALUES:
      {
        const {
          viewId,
          pageType
        } = action.payload;
        draft.currentViewId = viewId;
        draft.currentPageType = pageType;
        return draft;
      }
    case OPTIMISTICALLY_UPDATE_CURRENT_VIEW_ID:
      {
        const {
          viewId
        } = action.payload;
        draft.currentViewId = viewId;
        return draft;
      }
    case RESTORE_CACHED_VALUES:
      {
        const {
          objectTypeId,
          viewId,
          pageType,
          views,
          hasData
        } = action.payload;
        if (hasData) {
          mutableUpdateIn(['data', objectTypeId], currentViews => Object.assign({}, currentViews, views), draft);
          draft.currentViewId = viewId;
          draft.currentPageType = pageType;
        }
        return draft;
      }
    case STORE_VIEWS:
      {
        const {
          objectTypeId,
          views
        } = action.payload;

        // If there are locally-cached changes to a view, we shouldn't overwrite them.
        const viewsToMerge = filter(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(__view: $tsMigrateAny, viewId: ... Remove this comment to see the full error message
        (__view, viewId) => !hasIn(['data', objectTypeId, viewId], draft), views);
        mutableUpdateIn(['data', objectTypeId], currentViews => Object.assign({}, currentViews, viewsToMerge), draft);
        mutableUpdateIn(['cachedData', objectTypeId], currentViews => Object.assign({}, currentViews, viewsToMerge), draft);
        return draft;
      }
    // This is the same as STORE_VIEWS, but called when we want to squash any views in state.
    // This is for cases where the views have been created or updated and we would like to
    // use the fresh view returned from the create/update request.
    case REPLACE_VIEWS:
      {
        const {
          objectTypeId,
          views
        } = action.payload;
        mutableUpdateIn(['data', objectTypeId], currentViews => Object.assign({}, currentViews, views), draft);
        mutableUpdateIn(['cachedData', objectTypeId], currentViews => Object.assign({}, currentViews, views), draft);
        return draft;
      }
    // Type-check disabled cases because of the way we're using the `pipelineChanged` action without a constant type name
    case pipelineChanged.toString():
      {
        const {
          objectTypeId,
          pipelineId
        } = action.payload;
        // TODO: We shouldn't be storing this in this reducer. Pass viewId from action.
        const viewId = draft.currentViewId;
        if (pipelineId === ALL_PIPELINES_VALUE) {
          mutableDeleteIn(['data', objectTypeId, viewId, 'state', 'pipelineId'], draft);
        } else {
          mutableSetIn(['data', objectTypeId, viewId, 'state', 'pipelineId'], pipelineId, draft);
        }
        return draft;
      }
    default:
      {
        // cannot enable non-exhaustive-checking because of pipelineChanged.toString() above
        return draft;
      }
  }
}, initialState);