import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openModalAction, closeModalAction } from '../actions/overlayActions';
import { CREATE_VIEW_MODAL, EXPORT_VIEW_MODAL, BOARD_SORT_MODAL, EMPTY_STATE_WELCOME_MODAL, PRODUCT_PAYMENT_LINK_MODAL, CONTACT_DATA_IMPORT_MODAL, CREATE_OBJECT_FROM_PRODUCT_MODAL, CONVERT_DEAL_TO_COMMERCE_TOOL_MODAL } from '../constants/modalTypes';
export const useModalActions = () => {
  const dispatch = useDispatch();
  const openModal = useCallback((type, data) => dispatch(openModalAction(type, data)), [dispatch]);
  const closeModal = useCallback(() => dispatch(closeModalAction()), [dispatch]);
  const openViewExportModal = useCallback(({
    viewId,
    exportPageType
  } = {}) => openModal(EXPORT_VIEW_MODAL, {
    viewId,
    exportPageType
  }), [openModal]);
  const openCreateViewModal = useCallback(viewIdToClone => openModal(CREATE_VIEW_MODAL, {
    viewIdToClone
  }), [openModal]);
  const openBoardSortModal = useCallback(() => openModal(BOARD_SORT_MODAL), [openModal]);
  const openEmptyStateWelcomeModal = useCallback(({
    modalTextType,
    onCloseModal,
    onGuidedSetupClick,
    onManualSetupClick
  }) => openModal(EMPTY_STATE_WELCOME_MODAL, {
    modalTextType,
    onCloseModal,
    onGuidedSetupClick,
    onManualSetupClick
  }), [openModal]);
  const openProductPaymentLinkModal = useCallback(({
    productId
  }) => openModal(PRODUCT_PAYMENT_LINK_MODAL, {
    productId
  }), [openModal]);
  const openCreateObjectFromProductModal = useCallback(({
    productId
  }) => openModal(CREATE_OBJECT_FROM_PRODUCT_MODAL, {
    productId
  }), [openModal]);
  const openContactDataImportModal = useCallback(() => openModal(CONTACT_DATA_IMPORT_MODAL, {}), [openModal]);
  const openConvertDealToCommerceToolModal = useCallback(() => openModal(CONVERT_DEAL_TO_COMMERCE_TOOL_MODAL, {}), [openModal]);
  return {
    openViewExportModal,
    openCreateViewModal,
    openBoardSortModal,
    openEmptyStateWelcomeModal,
    openContactDataImportModal,
    openProductPaymentLinkModal,
    openCreateObjectFromProductModal,
    openConvertDealToCommerceToolModal,
    closeModal
  };
};