import { useFrameworkAppSettings } from '../context/AppSettingsContext';
import { useSelectedObjectTypeDef } from '../../crmObjects/hooks/useSelectedObjectTypeDef';
import { getAppSettingForTypeDef, getFieldValue } from '../utils/getFieldValue';
import { CRM_INDEX_PAGE_APP_SETTING } from '../constants/AppSettings';
export const useIndexPageAppSettingFieldValue = fieldName => {
  const appSettings = useFrameworkAppSettings();
  const typeDef = useSelectedObjectTypeDef();
  if (!typeDef) {
    return null;
  }
  const metadata = getAppSettingForTypeDef(appSettings, typeDef, CRM_INDEX_PAGE_APP_SETTING);
  return getFieldValue(metadata, fieldName);
};