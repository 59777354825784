const createNewDatawellEntry = reportId => ({
  location: 'CRM_INDEX_VIEW_COMPONENTS',
  config: {
    components: [{
      type: 'REPORT',
      area: 'DATA_WELL',
      componentId: reportId,
      visibility: 'NORMAL'
    }]
  }
});
const processConfigurationComponent = (component, reportId) => {
  if (component.area === 'DATA_WELL') {
    const didChange = component.componentId !== reportId;
    return {
      component: Object.assign({}, component, {
        componentId: reportId
      }),
      didChange
    };
  }
  return {
    component,
    didChange: false
  };
};
const processConfigurationEntry = (entry, reportId) => {
  const componentResults = entry.config.components.map(component => processConfigurationComponent(component, reportId));
  return {
    entry: Object.assign({}, entry, {
      config: Object.assign({}, entry.config, {
        components: componentResults.map(result => result.component)
      })
    }),
    didChange: componentResults.some(result => result.didChange)
  };
};
export const generateUpdatedViewConfiguration = (originalView, reportId) => {
  var _originalView$state$v, _originalView$state$v2;
  const hasDatawell = !!((_originalView$state$v = originalView.state.viewConfiguration) !== null && _originalView$state$v !== void 0 && _originalView$state$v.some(entry => entry.config.components.some(component => component.area === 'DATA_WELL')));
  const updateResults = ((_originalView$state$v2 = originalView.state.viewConfiguration) === null || _originalView$state$v2 === void 0 ? void 0 : _originalView$state$v2.map(entry => {
    var _entry$config$compone;
    const hasDatawellComponent = (_entry$config$compone = entry.config.components) === null || _entry$config$compone === void 0 ? void 0 : _entry$config$compone.some(component => component.area === 'DATA_WELL');
    return hasDatawellComponent ? processConfigurationEntry(entry, reportId) : {
      entry,
      didChange: false
    };
  })) || [];
  const finalConfiguration = hasDatawell ? updateResults.map(result => result.entry) : [...updateResults.map(result => result.entry), createNewDatawellEntry(reportId)];
  const didAnyChange = hasDatawell ? updateResults.some(result => result.didChange) : true; // Adding new datawell always counts as a change

  return didAnyChange ? {
    type: 'reportIdUpdated',
    updatedConfiguration: finalConfiguration
  } : {
    type: 'reportIdNotChanged'
  };
};