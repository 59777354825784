import { useModalActions } from '../../overlay/hooks/useModalActions';
import { isComingFromCommerceHubUserGuide } from 'growth-payments-core/urls/utils';
import { getQueryStringParameter } from 'growth-payments-core/query-string/utils';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { denormalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { DEAL } from 'customer-data-objects/constants/ObjectTypes';
import { useCallback } from 'react';
const CONVERT_DEAL_TO_TOOL_TASK_KEY = 'commerce-convert-deal-to-tool';
const getIsConvertDealToToolTask = () => getQueryStringParameter('taskKey') === CONVERT_DEAL_TO_TOOL_TASK_KEY;
export const useShouldSetupConvertDealToCommerceToolTour = () => {
  const currentObjectType = denormalizeTypeId(useSelectedObjectTypeId());
  return isComingFromCommerceHubUserGuide() && getIsConvertDealToToolTask() && currentObjectType === DEAL;
};
export const useOpenConvertDealToToolModal = () => {
  const {
    openConvertDealToCommerceToolModal
  } = useModalActions();
  const shouldSetupConvertDealToCommerceToolTour = useShouldSetupConvertDealToCommerceToolTour();
  return useCallback(() => {
    if (shouldSetupConvertDealToCommerceToolTour) {
      openConvertDealToCommerceToolModal();
    }
  }, [shouldSetupConvertDealToCommerceToolTour, openConvertDealToCommerceToolModal]);
};