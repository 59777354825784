import set from 'transmute/set';
import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { generateApolloCacheId } from '../utils/generateApolloCacheId';
import { rewriteObjectPropertiesAsMap } from '../../rewrite/crmSearch/utils/rewriteObjectPropertiesAsMap';
import { CrmObjectFragment } from '../../rewrite/crmSearch/hooks/useCrmSearchQuery';
import { useQueryProperties } from '../../rewrite/searchQuery/hooks/useQueryProperties';
import { mergeUpdatesIntoCrmObject } from '../../rewrite/localMutations/utils/mergeUpdatesIntoCrmObject';
import invariant from 'react-utils/invariant';
export const useApolloCacheActions = () => {
  const typeId = useSelectedObjectTypeId();
  // HACK: We need the exact set of properties used in the query to make sure we can pull the object
  // out of Apollo's cache. This can be fixed by providing a custom read/merge pair for the properties
  // typePolicy.
  const properties = useQueryProperties();

  // TODO: When the query to fetch multiple crm objects by ids is ready and uses cache redirects,
  // use that here instead. This is blocked by the common GraphQL client.
  const client = useApolloClient();
  const readObjects = useCallback((objectIds, objectTypeId = typeId) => objectIds.map(objectId => {
    const cacheId = generateApolloCacheId(objectTypeId, objectId);
    const object = client.readFragment({
      id: cacheId,
      fragment: CrmObjectFragment,
      variables: {
        properties
      }
    });
    return rewriteObjectPropertiesAsMap(object);
  }), [client, typeId, properties]);
  const writeObjects = useCallback((objects, objectTypeId = typeId) => {
    objects.forEach(object => {
      const cacheId = generateApolloCacheId(objectTypeId, object.objectId);

      // Here we're converting the object's 'properties' map back to an array since that's what
      // Apollo expects. It's the opposite of rewriteObjectPropertiesAsMap in the read function.
      const objectProperties = Object.values(object.properties);
      const unfetchedProperties = objectProperties.filter(
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      ({
        id,
        name
      }) => id == null || name == null // It is valid for value to be undefined/null
      );

      // Apollo explodes when we try to write a property that doesn't have an id or name field. Unfortunately
      // we cannot derive id -- we must get it from the backend. Therefore it is not safe to write any property
      // values unless they were fetched from the BE first.
      invariant(unfetchedProperties.length === 0, 'useApolloCacheActions:writeObjects - Trying to write unfetched properties %s to object %s. Please ensure you are only updating properties that have been fetched.', unfetchedProperties, cacheId);
      client.writeFragment({
        id: cacheId,
        fragment: CrmObjectFragment,
        variables: {
          properties
        },
        data: set('properties', objectProperties, object)
      });
    });
  }, [client, properties, typeId]);
  const updateObjects = useCallback(({
    objectIds,
    objectTypeId = typeId,
    propertyUpdates
  }) => {
    const objects = readObjects(objectIds, objectTypeId);
    writeObjects(objects.filter(Boolean).map(object => mergeUpdatesIntoCrmObject(object, propertyUpdates)), objectTypeId);
  }, [typeId, readObjects, writeObjects]);
  return {
    readObjects,
    writeObjects,
    updateObjects
  };
};