import { CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { useIsFreeOrStarterPortal } from 'onboarding-scopes';
import { useIsEmptyListOrBoard } from '../../crm_ui/hooks/useIsEmptyListOrBoard';
export const useShouldShowImportEmptyStateModal = objectType => {
  const isFreeOrStarterPortal = useIsFreeOrStarterPortal();
  // Import empty state is based on the contact object type
  const {
    isEmptyPageLoading,
    isEmptyPage
  } = useIsEmptyListOrBoard(CONTACT);
  return objectType === CONTACT && isFreeOrStarterPortal && !isEmptyPageLoading && isEmptyPage;
};