import memoizeOne from 'react-utils/memoizeOne';
import { useCurrentPageType } from '../../../views/hooks/useCurrentPageType';
import { BOARD } from '../../../views/constants/PageType';
import { getPropertiesFromView } from '../utils/getPropertiesFromView';
import { useSelectedObjectTypeDef } from '../../../crmObjects/hooks/useSelectedObjectTypeDef';
import { getEagerlyFetchPropertyList } from 'customer-data-property-utils/constants/EagerlyFetchPropertyList';
import { unique } from '../../../utils/unique';
import { useViews } from '../../../views/hooks/useViews';
import get from 'transmute/get';
import { useCurrentViewId } from '../../../views/hooks/useCurrentViewId';
import { useTagPropertyName } from '../../../board/hooks/useTagPropertyName';
export const getRequiredPropertiesForTypeDef = ({
  objectTypeId,
  pipelinePropertyName,
  pipelineStagePropertyName,
  primaryDisplayLabelPropertyName
}) => [...(getEagerlyFetchPropertyList(objectTypeId) || []),
// HACK: We need to make sure we always fetch this property because it's one of the
// only ones we can depend on to have a value. Once graphql returns deleted objects as
// "null" instead of just making the property values "null", we can remove this.
// See https://git.hubteam.com/HubSpot/CrmMiddlewarePlatformTeam/issues/252
// See also: makePropertiesQuickFetch, useCrmSearchQuery, where this filtering takes place.
pipelinePropertyName, pipelineStagePropertyName, primaryDisplayLabelPropertyName].filter(Boolean);
export const generateBoardProperties = memoizeOne((typeDef, tagPropertyName) => unique([...getRequiredPropertiesForTypeDef(typeDef), ...(tagPropertyName ? [tagPropertyName] : [])]).sort());
export const generateListProperties = memoizeOne((typeDef, view) => unique([...getRequiredPropertiesForTypeDef(typeDef),
// @ts-expect-error ts-migrate(2488) FIXME: Type 'Iterable<unknown, any>' must have a '[Symbol... Remove this comment to see the full error message
...getPropertiesFromView(view)]).sort());
export const useQueryProperties = () => {
  const pageType = useCurrentPageType();
  const typeDef = useSelectedObjectTypeDef();
  const tagPropertyName = useTagPropertyName();

  // Please do not use useViewById/useCurrentView here -- we need the columns
  // from before any validation has taken place. Properties will not always
  // be fetched by the time this hook is called, so if we were using the
  // validated columns they might change when the properties did get fetched
  // and trigger an unnecessary trip to the BE.
  // TODO: This could probably be fixed by making Apollo's cache treat the properties
  // array as a pool, but that is blocked by the centralized Apollo client.
  const viewId = useCurrentViewId();
  const views = useViews();
  const view = get(viewId, views);
  return pageType === BOARD ? generateBoardProperties(typeDef, tagPropertyName) : generateListProperties(typeDef, view);
};